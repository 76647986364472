import { Box, styled, Typography } from "@mui/material";

export const FooterContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: 228,
  background: theme.palette.primary.main,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "35px 0",
  [theme.breakpoints.down("lg")]: {
    height: "auto",
    justifyContent: "flex-start",
    padding: "32px 16px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "24px 16px",
  },
}));

export const FooterMainWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  width: 1088,
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    width: "auto",
  },
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}));

export const LogoWrapper = styled(Box)(({ theme }) => ({
  marginBottom: 16,
  [theme.breakpoints.down("lg")]: {
    marginBottom: 32,
  },
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 12,
  },
}));

export const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  color: "#F5F5F5",
  maxWidth: 200,
  [theme.breakpoints.down("lg")]: {
    maxWidth: "none",
    lineHeight: "140%",
    marginTop: 8,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    lineHeight: "normal",
    maxWidth: "none",
    marginTop: 8,
  },
}));

export const FooterCopyright = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  color: "#F5F5F5",
  opacity: 0.45,
  [theme.breakpoints.down("md")]: {
    fontSize: 10,
  },
}));

export const CopyRightWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  marginTop: 32,
  gap: 16,
  [theme.breakpoints.down("md")]: {
    marginTop: 16,
    gap: 8,
  },
}));

import { useTranslation } from "react-i18next";
import { FooterLinksItem } from "src/components/footerLink/styles";
import TermsOfUsePdfFile from "src/shared/assets/pdfs/terms_of_use.pdf";

export const TermsOfUsePdf = () => {
  const { t } = useTranslation();

  const handleClickTermsOfUse = () => {
    window.open(TermsOfUsePdfFile, "_blank");
  };

  return (
    <FooterLinksItem onClick={handleClickTermsOfUse}>
      {t("terms-of-use")}
    </FooterLinksItem>
  );
};

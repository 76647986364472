import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Popover } from "@mui/material";
import dayjs from "dayjs";
import {
  AgGridTitle,
  AgGridTitleWrapper,
} from "src/shared/components/agGrid/styled";
import {
  useManifestDetailExportFileMutation,
  useManifestListExportFileMutation,
} from "src/shared/store/api/manifest.api";
import { ReactComponent as ArrowDown } from "src/shared/assets/svgs/arrow-down.svg";
import { getHasValue } from "src/components/inspectionsTable/inspectionsFilters/constants";
import { useAppSelector } from "src/shared/hooks/redux/redux";
import {
  ArrowDownWrapper,
  ExportManifestPopoverItem,
  ExportDescription,
} from "./styles";

export const ManifestCustomTitle = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const today = dayjs(new Date());
  const yearBefore = dayjs(new Date()).subtract(365, "days");
  const [detailExportFile] = useManifestDetailExportFileMutation();
  const [manifestListExportFile] = useManifestListExportFileMutation();
  const {
    keyword,
    accounts,
    startDate,
    endDate,
    hasAttachment,
    discrepancy,
    statuses,
    compliances,
    sites,
    facilities,
  } = useAppSelector((state) => state.manifestFiltersReducer);

  const open = Boolean(anchorEl);
  const id = open ? "manifest-export-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportBody = {
    keyword: keyword,
    startDate: startDate ? dayjs(new Date(startDate?.toString())) : yearBefore,
    endDate: endDate ? dayjs(new Date(endDate?.toString())) : today,
    hasAttachment: getHasValue(hasAttachment),
    discrepancy,
    statusList: statuses,
    complianceList: compliances,
    accountIDList: accounts?.map((account) => account?.id),
    siteIDList: sites?.map((site) => site?.id),
    facilityIDLiSt: facilities?.map((facility) => facility?.id),
  };

  const handleListExport = () => {
    manifestListExportFile(exportBody);
    handleClose();
  };

  const handleDetailExport = () => {
    detailExportFile(exportBody);
    handleClose();
  };

  return (
    <AgGridTitleWrapper>
      <AgGridTitle>{t("list-of-manifests")}</AgGridTitle>
      <ArrowDownWrapper aria-describedby={id} onClick={handleClick} open={open}>
        <ExportDescription>{t("export")}</ExportDescription>
        <ArrowDown />
      </ArrowDownWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ExportManifestPopoverItem onClick={handleListExport}>
          {t("manifest-list")}
        </ExportManifestPopoverItem>
        <ExportManifestPopoverItem onClick={handleDetailExport}>
          {t("waste-detail")}
        </ExportManifestPopoverItem>
      </Popover>
    </AgGridTitleWrapper>
  );
};

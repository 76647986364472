import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const NewUserContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  maxWidth: 368,
  [theme.breakpoints.down("md")]: {
    padding: 16,
  },
}));

export const NewUserTitle = styled(Typography)(({ theme }) => ({
  color: "#282828",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "center",
  marginTop: 59,
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    marginTop: 54,
  },
}));

export const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 24,
  marginTop: 32,
  [theme.breakpoints.down("md")]: {
    gap: 16,
    marginTop: 24,
  },
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  marginTop: 32,
  [theme.breakpoints.down("md")]: {
    marginTop: 24,
  },
}));

export const NewUserCheckboxFieldWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: 8,
  alignItems: "center",
  "& > div,textArea": {
    display: "flex",
    maxWidth: 400,
    width: "100%",
    flexWrap: "wrap",
    "& .MuiFormHelperText-root": {
      textWrap: "nowrap",
      top: 24,
    },
    "& > div,textArea": {
      "& .MuiFormHelperText-root": {
        textWrap: "nowrap",
        top: 24,
      },
      height: 24,
    },
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export const NewUserTermsOfUseLabel = styled(Typography)(({ theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  lineHeight: "22px",
  letterSpacing: "0em",
  display: "flex",
  gap: 8,
  "& > span": {
    color: "#217BDB",
    cursor: "pointer",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 14,
    minWidth: 120,
  },
}));

import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import BlockUi from "@availity/block-ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { FilledButton } from "src/shared/components/filledButton/filledButton";
import { InputField } from "src/shared/components/inputField/inputField";
import { MainBackground } from "src/shared/components/mainBackground/mainBackground";
import { Return } from "src/shared/components/return/return";
import { Steps } from "src/shared/components/steps/steps";
import { CircularProgress, Typography } from "@mui/material";
import {
  useGetUserProfileQuery,
  useUpdateUserInvitationMutation,
} from "src/shared/store/api/userInvitation.api";
import { ErrorNewUser } from "src/components/errorNewUser/errorNewUser";
import { FormCardCheckbox } from "src/shared/components/formCard/formCardCheckbox/formCardCheckbox";
import TermsOfUsePdfFile from "src/shared/assets/pdfs/terms_of_use.pdf";
import { INewUserFields } from "./types";
import { NewUserValidationSchema } from "./model";
import { NEW_USER_FIELDS } from "./constants";
import {
  ButtonWrapper,
  NewUserContainer,
  NewUserTitle,
  InputWrapper,
  NewUserCheckboxFieldWrapper,
  NewUserTermsOfUseLabel,
} from "./styles";

export const NewUser = () => {
  const [formStep, setFormStep] = useState(0);
  const { id, verificationCode } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(NewUserValidationSchema(t)),
    mode: "onSubmit",
  });

  const termsOfUse = watch("termsOfUse") || false;

  const {
    data: userProfile,
    isLoading: isLoadingUserProfile,
    isError: isErrorUserProfile,
  } = useGetUserProfileQuery({
    id,
    verificationCode,
  });

  const [
    updateUser,
    {
      isLoading: isLoadingUpdateUser,
      data: updateUserData,
      error: errorUpdateUser,
      isSuccess: isSuccessUpdateUser,
    },
  ] = useUpdateUserInvitationMutation();

  const navigateToSignIn = useCallback(() => {
    navigate("/sign-in");
  }, [navigate]);

  const handleNewUser = handleSubmit((newUserData) => {
    updateUser({ ...userProfile, ...newUserData, verificationCode });
  });

  useEffect(() => {
    if (userProfile) {
      for (const value of Object.keys(userProfile)) {
        if (NEW_USER_FIELDS.includes(value)) {
          setValue(value as INewUserFields, userProfile[value]);
        }
      }
    }
  }, [userProfile, setValue]);

  useEffect(() => {
    if (isSuccessUpdateUser) {
      reset(
        NEW_USER_FIELDS.reduce((acc, val) => {
          return { ...acc, [val]: "" };
        }, {})
      );
      window.open(updateUserData, "_self");
    }
  }, [isSuccessUpdateUser, reset, updateUserData]);

  useEffect(() => {
    if (errorUpdateUser) {
    }
  }, [errorUpdateUser]);

  useEffect(() => {
    if (isErrorUserProfile) {
      setFormStep(1);
    }
  }, [isErrorUserProfile]);

  const handleChangeAnswerRequired = () => {
    setValue("termsOfUse", !termsOfUse);
  };

  const handleClickTermsOfUse = () => {
    window.open(TermsOfUsePdfFile, "_blank");
  };

  return (
    <BlockUi
      tag="div"
      blocking={isLoadingUpdateUser || isLoadingUserProfile}
      loader={<CircularProgress />}
      keepInView
    >
      <MainBackground>
        <NewUserContainer>
          <Typography></Typography>
          <Steps size={2} selected={formStep} />
          {formStep === 0 && (
            <React.Fragment>
              <NewUserTitle>{t("new-user-information")}</NewUserTitle>
              <InputWrapper>
                <InputField
                  placeholder={t("first-name")}
                  errorMessage={errors?.firstName?.message}
                  register={{ ...register("firstName") }}
                  id="new-user-first-name"
                />
                <InputField
                  placeholder={t("last-name")}
                  errorMessage={errors?.lastName?.message}
                  register={{ ...register("lastName") }}
                  id="new-user-last-name"
                />
                <InputField
                  placeholder={t("organization")}
                  errorMessage={errors?.organizationName?.message}
                  register={{ ...register("organizationName") }}
                  disabled
                  id="new-user-organization"
                />
                <InputField
                  placeholder={t("email")}
                  errorMessage={errors?.email?.message}
                  disabled
                  register={{ ...register("email") }}
                  id="new-user-email"
                />
                <InputField
                  placeholder={t("phone")}
                  errorMessage={errors?.phone?.message}
                  register={{ ...register("phone") }}
                  id="new-user-phone"
                />
                <InputField
                  placeholder={t("job-title")}
                  errorMessage={errors?.jobTitle?.message}
                  register={{ ...register("jobTitle") }}
                  id="new-user-job-title"
                />
                <NewUserCheckboxFieldWrapper>
                  <NewUserTermsOfUseLabel>
                    {t("i-have-read-and-agree")}
                    <span onClick={handleClickTermsOfUse}>
                      {t("terms-of-use")}
                    </span>
                  </NewUserTermsOfUseLabel>
                  <FormCardCheckbox
                    checked={termsOfUse}
                    onChange={handleChangeAnswerRequired}
                    id="edit-manage-inspection-answer-required"
                  />
                </NewUserCheckboxFieldWrapper>
              </InputWrapper>
              <ButtonWrapper>
                <FilledButton
                  text={t("submit")}
                  onClick={handleNewUser}
                  disabled={
                    isLoadingUpdateUser || isLoadingUserProfile || !termsOfUse
                  }
                  id="new-user-submit"
                />
              </ButtonWrapper>
              <Return onClick={navigateToSignIn} />
            </React.Fragment>
          )}
          {formStep === 1 && <ErrorNewUser />}
        </NewUserContainer>
      </MainBackground>
    </BlockUi>
  );
};

import { TimePicker as MuiTimePicker } from "@mui/x-date-pickers";
import { Box, styled } from "@mui/material";
import { isDarkMode } from "src/shared/hooks/theme/theme";
import { IArrowWrapper } from "./types";

export const StyledTimePicker = styled(MuiTimePicker)(({ theme }) => ({
  width: "100%",
  "& .MuiFormHelperText-root": {
    top: 24,
  },
  "& > div": {
    borderRadius: 8,
    background: isDarkMode(theme.palette.mode) ? "#35363A" : "#FFF",
    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#B1B1B1",
      },
      "&:hover fieldset": {
        borderColor: isDarkMode(theme.palette.mode)
          ? "rgba(37, 137, 92, 0.5)"
          : "rgba(37, 137, 92, 0.20)",
      },
      "&.Mui-focused fieldset": {
        borderColor: isDarkMode(theme.palette.mode)
          ? "rgba(32, 33, 36, 0.50)"
          : "#25895C",
      },
    },
    "& > input": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
      padding: "2px 8px !important",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down("lg")]: {
        fontSize: 14,
      },
    },
    "& > div > button": {
      padding: 0,
      right: 4,
    },
  },
}));

export const ArrowWrapper = styled(Box)<IArrowWrapper>(({ theme, opened }) => ({
  width: 20,
  height: 20,
  display: "flex",
  transform: opened ? "rotate(180deg)" : "",
  [theme.breakpoints.down("lg")]: {
    width: 16,
    height: 16,
  },
  "& > svg": {
    width: "inherit",
    height: "inherit",
    cursor: "pointer",
    "& > path": {
      fill: isDarkMode(theme.palette.mode) ? "#FFF" : "#B1B1B1",
    },
  },
}));

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BlockUi from "@availity/block-ui";
import { useTranslation } from "react-i18next";
import { CircularProgress, Popover } from "@mui/material";
import { useAppSelector } from "src/shared/hooks/redux/redux";
import {
  AgGridTitle,
  AgGridTitleWrapper,
} from "src/shared/components/agGrid/styled";
import { ReactComponent as ArrowDown } from "src/shared/assets/svgs/arrow-down.svg";
import { useLazyGetInspectionExportWithPhotosQuery } from "src/shared/store/api/inspection.api";
import { getListOfIdsFromObject } from "../inspectionsFilters/constants";
import { ICustomInspectionsTitle } from "./types";
import { ArrowDownWrapper, ExportDescription, PopoverItem } from "./styles";

export const CustomInspectionsTitle = ({
  gridApi,
}: ICustomInspectionsTitle) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    keyword,
    account: inspectionsAccount,
    sites,
    buildings,
    rooms,
    locations,
    contacts,
    departments,
    startDate,
    endDate,
    followUp,
  } = useAppSelector((state) => state.inspectionsFiltersReducer);

  const [
    getInspectionExportWithPhotos,
    { isFetching: isFetchingGetInspectionExportWithPhotos },
  ] = useLazyGetInspectionExportWithPhotosQuery({});

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const open = Boolean(anchorEl);
  const id = open ? "custom-inspection-title" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickPrintToExcel = () => {
    const allColumns = gridApi?.getColumns();
    const columnsToExport = allColumns?.filter((col: any) => {
      return col.getColId() !== "arrowRow" && col.visible === true;
    });

    const params = {
      columnKeys: columnsToExport?.map((col) => col.getColId()),
      processCellCallback: (params: any) => {
        const colDef = params.column.getColDef();
        const valueFormatter = colDef.valueFormatter;

        if (valueFormatter) {
          return valueFormatter({
            value: params.value,
            data: params.data,
            column: params.column,
            rowIndex: params.rowIndex,
          });
        } else {
          return params.value;
        }
      },
    };

    gridApi?.exportDataAsExcel(params);
  };

  const handleClickManageInspections = () => {
    navigate("/manage-inspections");
  };

  const handleClickExportPhotos = () => {
    getInspectionExportWithPhotos({
      keyword,
      startDate,
      endDate,
      accountIDList: [inspectionsAccount?.id],
      siteIDList: getListOfIdsFromObject(sites),
      buildingIDList: getListOfIdsFromObject(buildings),
      roomIDList: getListOfIdsFromObject(rooms),
      storageIDList: getListOfIdsFromObject(locations),
      departmentIDList: getListOfIdsFromObject(departments),
      followUpOptionIDList: getListOfIdsFromObject(followUp),
      contactIDList: getListOfIdsFromObject(contacts),
      hasAttachments: true,
    });
  };

  return (
    <AgGridTitleWrapper>
      <AgGridTitle>{t("list-of-inspections")}</AgGridTitle>
      <ArrowDownWrapper aria-describedby={id} onClick={handleClick} open={open}>
        <ExportDescription>{t("menu")}</ExportDescription>
        <ArrowDown />
      </ArrowDownWrapper>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <BlockUi
          tag="div"
          blocking={isFetchingGetInspectionExportWithPhotos}
          loader={<CircularProgress />}
          keepInView
        >
          <PopoverItem onClick={handleClickPrintToExcel}>
            {t("print-to-excel")}
          </PopoverItem>
          <PopoverItem onClick={handleClickManageInspections}>
            {t("manage-inspections")}
          </PopoverItem>
          <PopoverItem onClick={handleClickExportPhotos}>
            {t("export-photos")}
          </PopoverItem>
        </BlockUi>
      </Popover>
    </AgGridTitleWrapper>
  );
};

import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { ReactComponent as ArrowDown } from "src/shared/assets/svgs/select-arrow.svg";
import { generatedIdForDatePicker } from "src/shared/helpers/generatedId";
import { StyledDatePicker, ArrowWrapper } from "./styles";
import { IDatePicker } from "./types";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrBefore);

export const DatePicker = ({
  value,
  onChange,
  errorMessage,
  minDate,
  maxDate,
  disabled,
  register,
  withClear,
  onClear,
  id,
}: IDatePicker) => {
  const [isOpenDatePicker, setIsOpenDatePicker] = useState(false);

  const onClose = () => {
    setIsOpenDatePicker(false);
  };

  const onOpen = () => {
    setIsOpenDatePicker(true);
  };

  const handleOpenDatePicker = () => {
    setIsOpenDatePicker(!isOpenDatePicker);
  };

  const slots = {
    openPickerIcon: () => (
      <ArrowWrapper opened={isOpenDatePicker}>
        <ArrowDown onClick={handleOpenDatePicker} />
      </ArrowWrapper>
    ),
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker
        {...register}
        open={isOpenDatePicker}
        onClose={onClose}
        disabled={disabled}
        minDate={minDate ? minDate : undefined}
        maxDate={maxDate ? maxDate : undefined}
        value={value}
        onChange={onChange}
        slots={slots}
        slotProps={{
          ...(withClear
            ? {
                actionBar: {
                  actions: ["clear"],
                  onClick: onClear,
                },
              }
            : {}),

          textField: {
            id: generatedIdForDatePicker(id),
            error: !!errorMessage,
            helperText: errorMessage,
            onClick: onOpen,
          },
        }}
      />
    </LocalizationProvider>
  );
};
